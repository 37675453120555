<template>
  <v-card class="teros-elevation" :color="tableColor">
    <v-card-title class="px-5 pb-0 text-uppercase v-title-cadastro">
      RESUMO DO TOTAL DE GASTOS POR PROJETO
    </v-card-title>
    <master-detail
      :canDelete="false"
      :canEdit="false"
      class="gastos-projetos-table"
      asyncExportType="resumo-de-gastos-por-projetos"
      :cols="cols"
      :customResource="customResource"
      :hasBeneficioFilter="true"
      :hasExportCSV="false"
      :hasExportXLSX="false"
      :hasMonthlyFilter="true"
      :hasNewButton="false"
      expandSubItem
      :lastRowData="lastRowData"
      :opts="opts"
    >
      <recalcule-btn
        class="table-v-action-button mr-3 pt-1"
        v-if="!isClient"
        @change="recalcularPeriodo($event)"
      ></recalcule-btn>
    </master-detail>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {
    "master-detail": () => import("@/components/master-detail.vue"),
    "recalcule-btn": () => import("@/components/recalcule-btn.vue")
  },
  methods: {
    updateLastRowData(value) {
      this.lastRowData = value;
    },
    objectTransform(row, index) {
      return {
        id: index,
        rh: row.rh,
        terceiros: row.terceiros,
        materiais: row.materiais,
        incentivo: row.incentivo,
        tituloProjeto: row.projeto.titulo,
        idProjeto: row.projeto.id,
        total:
          (row.rh ? row.rh * 1 : 0) +
          (row.terceiros ? row.terceiros * 1 : 0) +
          (row.materiais ? row.materiais * 1 : 0)
      };
    },
    recalcularPeriodo: function(intervalo) {
      this.resourceRecalculo
        .save({
          competenciaIni: intervalo[0],
          competenciaFim: intervalo[1],
          trabalho: this.servico,
          empresaId: this.clientId
        })
        .then(() =>
          this.$notify({
            group: "geral",
            duration: 5000,
            type: "success",
            title: "Recalculo solicitado.",
            text: "Teros irá refazer o cálculo em segundo plano."
          })
        );
    }
  },
  watch: {
    lastRowData: function(newVal) {
      this.updateLastRowData(newVal);
    }
  },
  computed: {
    ...mapGetters([
      "clientId",
      "intervaloCompetencia",
      "selectedClient",
      "servico"
    ]),
    isClient: function() {
      return this.getClient().isClient;
    },
    cols: function() {
      return [
        {
          name: "Projeto",
          key: "tituloProjeto",
          type: this.$fieldTypes.TEXT,
          width: "40%"
        },
        {
          name: "Recursos Humanos",
          key: "rh",
          align: 0,
          type: this.$fieldTypes.MONEY,
          width: "15%"
        },
        {
          name: "Serviços Terceiros",
          key: "terceiros",
          align: 0,
          type: this.$fieldTypes.MONEY,
          width: "15%"
        },
        {
          name: "Materiais",
          key: "materiais",
          align: 0,
          type: this.$fieldTypes.MONEY,
          width: "15%"
        },
        {
          name: "Total Dispêndios",
          key: "total",
          align: 0,
          type: this.$fieldTypes.MONEY,
          width: "15%"
        },
        {
          name: "Incentivo Fiscal (IRPJ e CSLL)",
          key: "incentivo",
          align: 0,
          type: this.$fieldTypes.MONEY,
          width: "15%",
          hideInTable: this.servico != 1
        }
      ];
    },

    customResource: function() {
      const resource = this.apiResource(
        `/v1/rh/clientes/${this.clientId}/dispendio/projetos`
      );
      let global = this;
      return {
        ...resource,
        get(...args) {
          return resource.get(...args).then(response => {
            if (Array.isArray(response) && response.length > 0) {
              const [res] = response;
              const data = res.map((row, index) =>
                !row.agrupamento
                  ? global.objectTransform(row, index)
                  : [
                      global.objectTransform(row, index),
                      row.projetos.map((row2, index2) =>
                        global.objectTransform(row2, index2)
                      ) || []
                    ]
              );

              global.lastRowData = data.reduce(
                (prev, curr) => {
                  prev.rh += curr.rh ? curr.rh * 1 : 0;
                  prev.terceiros += curr.terceiros ? curr.terceiros * 1 : 0;
                  prev.materiais += curr.materiais ? curr.materiais * 1 : 0;
                  prev.incentivo += curr.incentivo ? curr.incentivo * 1 : 0;
                  prev.total += curr.total ? curr.total * 1 : 0;
                  if (Array.isArray(curr)) {
                    curr[1].map(row => {
                      prev.rh += row.rh ? row.rh * 1 : 0;
                      prev.terceiros += row.terceiros ? row.terceiros * 1 : 0;
                      prev.materiais += row.materiais ? row.materiais * 1 : 0;
                      prev.incentivo += row.incentivo ? row.incentivo * 1 : 0;
                      prev.total += row.total ? row.total * 1 : 0;
                    });
                  }
                  return prev;
                },
                {
                  tituloProjeto: "Total",
                  rh: 0,
                  terceiros: 0,
                  materiais: 0,
                  incentivo: 0,
                  total: 0,
                  agrupamento: false
                }
              );
              return data;
            }
          });
        }
      };
    },
    resourceRecalculo: function() {
      return this.apiResource(
        `v1/rh/clientes/${this.clientId}/dispendio/recalresumoprojetos`
      );
    },
    exportOptions: function() {
      return {
        columns: this.colunas,
        periodoCompetencia: this.intervaloCompetencia,
        servico: this.servico,
        styles: [1].reduce(
          (acc, key) => ({
            ...acc,
            [key]: {
              font: {
                bold: true
              }
            }
          }),
          {}
        )
      };
    },
    opts: function() {
      return {};
    }
  },
  data: function() {
    return {
      lastRowData: null
      // lastRowData: {
      //   tituloProjeto: "Total",
      //   rh: 0,
      //   terceiros: 0,
      //   materiais: 0,
      //   total: 0,
      // },
    };
  }
};
</script>

<style lang="scss" scoped>
.gastos-projetos-table::v-deep .v-data-table__wrapper {
  height: calc(100vh - 300px) !important;
}
</style>
